body {

  &.page.legal,
  &.page.sitemap,
  &.page-template-template-no-sidebar {
    .main {
      margin: auto;
      max-width: $noSidebarWidth;
    }
  }

  &.page-template-overview,
  &.page.the-latest,
  &.page.contact,
  &.archive,
  &.page.university-transfer-program,
  &.blog,
  &.home,
  &.archive {
    .content {
      padding: 0;
      >.container {
        max-width: none;
        width: auto;
      }
    }
  }

  &.sidebar-primary {

    .sidebar {
      margin: 3em 0 0;
    }

    @media (min-width: $screen-sm) {

      .content {
        >.container {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .main {
        flex: 1 1 10em;
        margin: 0;
      }

      .sidebar {
        @include flex-width(15em);
        margin: 0 0 0 3em;
      }

    } // min-sm

    @media (min-width: $screen-md) {
      .sidebar {
        @include flex-width(18em);
      }
    } // min-md

    @media (min-width: $screen-lg) {
      .sidebar {
        @include flex-width(23.125em);
      }
    }

  } // &.sidebar-primary

} // body

.section-sitemap {
  @extend .section;

  >ul {
    @extend .nolist;
    display: inline-block;
    margin-right: 2em;


    &.sitemap-descendants {
      margin: 0.5em 0 1em;
      display: block;
    }

    >li {
      border-bottom: 1px solid rgba(black,0.1);

      >a {
        padding: 0.25em 0;
        display: block;
      }

      &:last-of-type {
        border-bottom: none;
      }

      ul {
        @extend .nolist;
        margin: 0.5em 0 0;

        li {

          a {
            color: $gray;
            position: relative;
            display: inline-block;
            padding-left: 1.25em;

            &:before {
              @include absolute(0.5em,auto,auto,0.5em);
              content: '';
              border-top: 0.25em solid transparent;
              border-bottom: 0.25em solid transparent;
              border-left: 0.25em solid $secondary;
              transition: all 300ms ease;
            }

            &:hover {
              color: $primary;
            }
          }

        } //li

        @media (min-width: $screen-sm) {
          display: flex;
          flex-wrap: wrap;

          li {
            @include flex-width(calc(50% - 2em));
            margin-right: 2em;
          }

        } // min-sm

        @media (min-width: $screen-md) {

          li {
            @include flex-width(calc(33.33% - 2em));
          }

        } // min-sm

      } //ul
    } // >li

  } // >ul

} // .section-sitemap


.titlebar {
  h1,h2,h3,h4 {
    margin: 0 0 0.25em;
  }

  h1 {
    color: white;
    text-transform: uppercase;
    @media(max-width: $screen-sm) {
      color: $primary;
    }
  }

  .subtitle {
    margin: 0 0 0.5em;
    font-size: 1em;
  }

  &.bg-primary {
    border: 0.125em solid white;
    border-width: 0.125em 0;
  }
}

.titlebar-overview {
  h1 {
    font-size: 1.6em;
    text-transform: uppercase;
  }
}

.section-watermark {
  position: relative;

  .blurb, .container, .testimonial {
    position: relative;
    z-index: 3;
  }

  .bg-image {
    @include absolute(0);
    z-index: 1;
    opacity: 0.15;
    background-size: cover;
    background-position: center center;
  }

} //section-watermark

.contact-info-cols {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2em;

  .contact-col {
    margin: 1em 2em;
    flex: 1 1 15em;
    max-width: 15em;

    h4 {
      margin-top: 0;
      margin-bottom: 1em;
    }

    strong {
      font-weight: 400;
    }

  }

  @media (min-width: $screen-sm) {

  }

} // .contact-info-cols

.section-split {

  h1,h2,h3 {
    margin-top: 0;
  }

  .subtitle {
    color: $gray-light;
  }

  .titlebar {
    margin: 0 0 1.5em;
  }

  .contact-info,
  .location-info {
    span {
      display: inline-block;
      margin: 0 0.75em 0 0;
    }
  }

  .social {
    margin: 0 -0.25em;

    li {
      a {
        padding: 0.25em;
      }
    }
  }

  .button {
    margin: 1em 0 0;
  }

  .split-col {
    position: relative;
  }

  .gmap {
    @include absolute(0);
    z-index: 5;
    width: 100%;
    height: 100%;
  }

  .split-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 56.25%;
  }

  .post-info {
    margin: 0 0 2em;
  }

  .split-image-secondary {
    margin-top: 3em;

    img {
      width: 100%;
    }
  }

  &.section-split-gutters {
    .split-image {
      padding: 0 0 3em;
    }
  }

  @media (min-width: $screen-sm) {
    display: flex;

    .split-col {
      flex: 1 1 50%;
    }
    .split-10{ flex:0 0 10%; }
    .split-20{ flex:0 0 20%; }
    .split-30{ flex:0 0 30%; }
    .split-40{ flex:0 0 40%; }
    .split-50{ flex:0 0 50%; }
    .split-60{ flex:0 0 60%; }
    .split-70{ flex:0 0 70%; }
    .split-80{ flex:0 0 80%; }
    .split-90{ flex:0 0 90%; }
    .content-left{ padding-right:1em; }
    .content-right{ padding-left:1em; }

    .split-image {
      padding-top: 0;
    }

    .container {
      width: 80%;
    }

    .split-image-secondary {
      margin-top: 0;
    }

    &.section-split-gutters {
      margin: 0 -1.5em;
      flex-wrap: wrap;

      .split-col {
        margin: 0 1.5em;
        flex: 1 1 calc(50% - 3em);
      }

      .split-image {
        padding: 0;
      }

      .split-image-event {
        flex: 1 1 calc(100% - 3em);
      }

    } // &.section-split-gutters

    &.align-center {
      align-items: center;

      .split-content {
        padding-bottom: 3em;
      }

    }

  } // min-sm

  @media (min-width: $screen-md) {

    &.section-split-gutters {
      .split-image-event {
        @include flex-width(10.625em);
        order: -1;
      }
    }

  }

} // .section-split

.section-alternating {
  .section-split:nth-child(odd) {
    @extend .bg-gray-lightest;
  }

  .section-grid:nth-child(odd) {
    @extend .bg-gray-lighter;
  }

} // .section-alternating

.section-overview {

  @media (min-width: $screen-sm) {

    .section-split.has-feat {

      &:nth-child(even) {

        .split-content {
          order: -1;
        }

      } // &nth-child even

    } // .section-split

  } // min-sm

} // .section-overview

.section-form {
  .form-columns {
    margin-top: 2em;
  }

  button {
    margin: 1em 0 0;
  }

  @media (min-width: $screen-sm) {

    .form-columns {
      display: flex;
      flex-wrap: wrap;
      margin: 2em -0.25em 0;
    }

    .form-col {
      @include flex-width(calc(50% - 0.5em));
      margin: 0 0.25em;
    }

    textarea {
      min-height: 16.0625em;
    }

  } // min-sm

  @media (min-width: $screen-md) {
    textarea { min-height: 9.375em; }
  }

} // .section-form

.post-meta {
  padding: 0 0 0.25em;
  margin: 0 0 1em;
  border-bottom: 1px solid rgba(black,0.1);

  p {
    display: inline-block;
    margin: 0 1em 0.25em 0;

    i {
      margin-right: 0.25em;
    }

    &.post-location-info {
      display: inline-block;

      span:first-of-type {
        position: relative;

        &:before {
          content: '\f041';
          font-family: 'FontAwesome';
          margin: 0 0.5em 0 0;
          // @include absolute(calc(50% - 0.5em), auto,auto,0);
        }
      }
    }

  }
}

.section-grid-centred {

  .tile-wrap {
    justify-content: center;
  }
}

.grid-service {
  display: flex;
  flex-wrap: wrap;
  margin: -1em;
}

.block-service {
  @include flex-width(calc(100% - 2em));
  margin: 1em;

  h3 { margin: 0; font-size: 1.75em; }
  hr { display: none; }

  ul {
    li {
      padding: 0.5em 0;
      border: 1px solid rgba(black,0.1);
      border-width: 0 0 1px;
    }
  }

  @media (min-width: $screen-xs) {
    @include flex-width(calc(50% - 2em));
  }

  @media (min-width: $screen-md) {
    @include flex-width(calc(33.33% - 2em));
  }

  @media (min-width: $screen-lg) {
    @include flex-width(calc(25% - 2em));
  }

} // .block-service
