.wrapper {
  transition: all 300ms ease; // needed for off-canvas nav
  position:relative;
  // top padding should be equivalent
  // to the height of the fixed menu
  // this will likely change per breakpoint
  // so be sure to adjust whenever needed
  padding-top:4em;

  @media (min-width: $screen-sm) {
    padding-top: 8em;
  }

  @media (min-width: $screen-md) {
    // padding-top: Xem;
  }

} // wrapper

.menu-off-canvas {
  // $offCanvasWidth defined in _variables.scss
  // feel free to adjust if needed
  @include fixed(0,0,0,auto);
  z-index: -1;
  width: $offCanvasWidth;
  height: 100%;
  overflow: scroll;
  transition: all 300ms ease;
  box-shadow: inset 0.25em 0 1em rgba(black,0.5);
  transform: translate3d($offCanvasWidth,0,0);
  a{ color:white; &:hover, &:focus{ color:$secondary; }}
  .container {
    padding: 2em 0;
    text-align:center;
    .menu-primary,.menu-secondary,a{
      text-align:center;
      font-size:16px;
      text-transform: uppercase;
      li{padding-bottom:2em;}
    }
    .social{
      text-align:center;
      font-size:18px;
    }
    .button{
      margin:0 auto 2em auto;
    }
    .menu-primary{ padding-top:2em; }
    .menu-secondary{ padding-top:1em; }
    .sub-menu{
      padding:0; margin:0;
      text-align:center;
      li{ padding:0; }
      a{
        font-size:12px !important;
        text-transform:capitalize !important;
      }
    }
  }

} // .menu-off-canvas

.menu-off-canvas.left {
  // add class to menu-off-canvas to change the side it appears from
  left: 0;
  right: auto;
  transform: translate3d(-$offCanvasWidth,0,0);
} // .menu-off-canvas.left

.menu-fixed {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 300ms ease;
  box-shadow: 0 0 0.25em rgba(black,0.1);
  border-top: 2px $primary solid;
  .brand{
    margin:1em auto 1em 0;
    transition:all 300ms ease-in-out;
    svg{
      @include flex-width(550px);
      max-height:103px;//for IE
      @media(max-width:$screen-sm){
        @include flex-width(300px);
        max-height:60px;//FOR IE
      }
    }//svg
  }//brand
  .bottombar .flex-container{
    @extend .container;
    display:flex;
    justify-content: space-between;
    align-items: stretch;
    .menu-primary{
      display:flex;
      align-items: stretch;
      li .container-dropdown:after {
        position: absolute;
        bottom: -29px;
        height: 22px;
        left: 0;
        right: 0;
        transition: all 1.2s ease-in-out;
        content: "";
      }
      li:hover .container-dropdown {
        opacity: 1;
        transition: all 1.2s ease-in-out;
        // transform: translate(-50%, 0%);
        top: 100%;
      }
      li:hover .dropdown .dropdown-content {
        opacity: 1;
        transition: all 1.2s ease-in-out;
        transform: translate(-50%, 0%);
        top: 100%;
      }
      a{
        color:white;
        font-weight:500;
        &:hover, &:focus{
          color:$primary; background: white;
        }
      }//a
      .current .dropdownbtn{
        padding-bottom:.5em;
        border-bottom:.5em solid white;
      }//current
      .menu-apply,.menu-search{
        display:flex;
        justify-content: center;
        align-items: center;
      }//menu-apply

      .menu-apply {
        margin-left:6em;
        a{
          &:hover, &:focus{ color:$primary !important;background: white !important;}
        }//a
      }
      .menu-search{
        padding: 0 1em 0 .5em !important;
        i{ margin-top:-4px;}
      }
      @media(max-width:$screen-md-max){
        display:none;
      }
    }//menu-primary
  }//container

  .dropdown {
    display:flex;
    .dropdownbtn{
      padding:1em 1.5em;
      font-family:$font;
      font-weight:500;
      color:$gray;
      font-size:14px;
      text-transform:uppercase;
      letter-spacing: 1px;
      text-align:center;
      display:flex;
      justify-content: center;
      align-items: center;
      cursor:pointer;
      &:hover,&:focus{
        color:$primary;
        .dropdown-content {
          transform:translateY(100%);
        }
      }
    }
    .dropdown-content {
      //display: none;
      position: absolute;
      top:100%;
      left:0; right:0;
      transform:translateY(-500%);
      transition:all 300ms ease-in-out;
      transition-delay:50ms;
      background-color: white;
      @include flex-width(100%);
      z-index: -1;
      padding:2em 0 1em;
      border-top:1px solid $gray-lighter;
      &:not(.scroll-trigger){
        box-shadow: 0 8px 19px rgba(0,0,0,0.22), 0 6px 6px rgba(0,0,0,0.11);
      }
      h3{
        font-size:18px;
        font-weight:500;
        color:black;
        text-transform:uppercase;
        margin-bottom:2em;
      }
      .child-pages{
        columns:2;
        column-gap:2em;
        li{
          border-left:1px solid $gray-lighter;
          padding:.25em 0 .25em 1em;
          a{
            font-size:14px;
            letter-spacing:1px;
            color:$gray-light;
            &:hover,&:focus{ color:$primary;}
          }

        }//li
      }//child-pages

      .sub-menu{
        display:flex;
        flex-wrap:wrap;
        margin:-1em;
        li{
          @include flex-width(25%);
          margin:1em;
          img{
            @include flex-width(100%);
          }
          a p:hover, a p:focus{
            color:$primary;
          }
        }
      }
    }
    .exit{
      text-align:center;
      color:$gray-light;
      font-size:12px;
      font-weight:200;
      padding-top:1em;
      cursor:pointer;
      &:hover, &:focus{
        color:$primary;
      }
    }//dropdown exit
    &.active{
      .dropdownbtn { background-color:$gray-lightest; }
      a {
        color: $primary !important;
      }
      .dropdown-content {
        display: block;
        transform:translateY(0);
      }
    }//hover
  }

  .search-and-language {
    display:flex;
    margin: 0.5em;
    .menu-login {
      margin-right: 1em;
    }
    a{
      font-size:10px;
      font-weight:500;
      text-transform:uppercase;
      letter-spacing: 1px;
      color:$gray;
      &:hover, &:focus{
        color:$primary;
      }
    }
    .divider{
      font-size:17px;
      color:lighten($gray-light,.2);
      margin:0 1em;
      font-family:$font;
      font-weight:300;
    }
    .menu-secondary li{
      display:inline-block;
      &:last-child{padding-right:1em;}
    }
    .active a{
      color:$primary;
      &:hover, &:focus{
        color:$primary;
      }
    }
  }
  @media(max-width:$screen-md-max){
    .menu-secondary, .menu-primary,.hide-me-md{
      display:none;
    }
  }
  @media(max-width:$screen-sm){
    .hide-me-sm{ display:none;}
  }
} // .menu-fixed

.menu-utility {
  display:flex;
  justify-content: flex-end;
  align-items: center;
  li {
    font-size: 1.5em;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    span {
      width: 1em;
      height: 1em;
      display: block;
      position: relative;
    }

    i {
      display: block;
      transition: all 300ms ease;
    }

    &.menu-open {
      display:none;
      transition: all 300ms ease;

      span {
        padding-top: 0.075em;
      }

      i {
        height: 0.083333333em;
        width: 1em;
        background: white;
        margin: 0 0 0.3em;
      }

      &:hover {
        i {
          background: $gray-light;
        }
      }
      @media(max-width:$screen-md-max){
        display:block;
      }
    } // .menu-open

    &.menu-search {
      i:first-of-type {
        @include absolute(0,auto,auto,0);
        width: 0.875em;
        height: 0.875em;
        border: 0.083333333em solid $gray-light;
        border-radius: 50%;
      }
      i:last-of-type {
        @include absolute(auto,0,0,auto);
        width: 0.35em;
        height: 0.083333333em;
        background: $gray-light;
        transform: rotate(50deg) translate3d(-0.1em,-.00125em,0);
      }

      &:hover {
        i:first-of-type {
          border-color: $primary;
        }
        i:last-of-type {
          background: $primary;
        }
      }
    }
  } // li

} // .menu-utility

@media screen {

  .knockout .menu-utility {
    li {

      &.menu-open {
        transition: all 300ms ease;

        span {
          padding-top: 0.075em;
        }

        i {
          background: rgba(white,0.7);
        }

        &:hover {
          i {
            background: white;
          }
        }
      } // .menu-open

      &.menu-search {
        i:first-of-type {
          border-color: rgba(white,0.7);
        }
        i:last-of-type {
          background:rgba(white,0.7);
        }

        &:hover {
          i:first-of-type {
            border-color: white;
          }
          i:last-of-type {
            background: white;
          }
        }
      }
    } // li
  } // .knockout .menu-utility

} // screen

.body-scrolled {
  // we typically find ways to reduce
  // the menu height after the user
  // scrolls down a bit

  .topbar{ max-height:0; overflow:hidden; }
  .bottombar{
    z-index:66666;
    box-shadow: 0 8px 19px rgba(0,0,0,0.22), 0 6px 6px rgba(0,0,0,0.11);

    .brand{
      margin:.5em auto .5em 0;
      svg {
        @include flex-width(350px);
        max-height: 60px; //for IE
        @media(max-width: $screen-sm) {
          @include flex-width(200px);
          max-height: 60px; //for IE
        }
      }

    }//brand
  }//bottombar

} // .body-is-scrolled

.menu-off-canvas-active {
  // body class to active off-canvas menu
  overflow: hidden;
  max-height: 100%;

  .wrapper,.menu-fixed { transform: translate3d(-$offCanvasWidth,0,0); }
  .scroll-trigger{ right:$offCanvasWidth; }
  .menu-off-canvas { transform: translate3d(0,0,0); z-index: 10001;}

  .menu-utility {

    li {
      &.menu-open {

        i {

          &:nth-child(2) {
            background: none;
            transform: rotate(270deg);
          }

          &:nth-child(1) {
            transform: rotate(135deg) translate3d(0.25em,-0.25em,0);
          }

          &:nth-child(3) {
            transform: rotate(-135deg) translate3d(0.3em,0.25em,0);
          }

        } // i

      } // &.menu-open
    } // li

  } //.menu-utility

  @media (min-width: $screen-md-max) {
    // disabled off canvas menu at the above breakpoint
    // change min-width if you need to disable before or
    // after this default breakpoint.
    overflow: visible;
    max-height: none;

    .wrapper,.menu-fixed{ transform: translate3d(0,0,0); }
    .menu-off-canvas { transform: translate3d($offCanvasWidth,0,0); z-index: 10001;}
  }

} // .menu-off-canvas-active

.menu-off-canvas-active.left {
  .wrapper,.menu-fixed { transform: translate3d($offCanvasWidth,0,0); }
  .menu-off-canvas.left {
    transform: none;
  }
} // .menu-off-canvas-active

.banner {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgba(black,0.1);

  .banner-image {
    height: $heroAspectRatio;
    overflow: hidden;
    animation: headerBigger 10s linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @media(max-width: $screen-sm) {
      height: 45vw;
      img {
        height: 100%;
      }
    }

    img {
      width: 100%;
      display: block;
    }

    @keyframes headerBigger {
      100% {
        transform: scale3d(1.3, 1.3, 1.3);
      }
    }
  }

  @media(max-width: $screen-sm) {
    .section-header {
      padding: 1em 0;
    }
  }

  .button {
    margin: 1.5em 0 0;
  }

  @media screen and (min-width: $screen-sm) {
    border: none;
    .bg-fade {
      @include absolute(0);
      opacity: 0.6;
      z-index: 2;
    }

    .overlay {
      @include absolute(0);
      z-index: 3;
      padding: 0;
      display: flex;
      align-items: center;
    }

    .blurb {
      flex: 1 1 100%;
      margin: auto 10%;
      max-width: none;
      .title-animation{
        overflow: hidden;
        .act-like-h1 {
          transform: translate(0,50px);
          opacity:0;
          transition: 0.6s all ease-in-out;
        }
      }
      .subtitle-animation {
        overflow: hidden;
        min-height: 2em;
        .subtitle {
          transform: translate(0,50px);
          opacity:0;
          transition: 0.6s all ease-in-out;
          width: fit-content;
          margin: auto;
          &.no-margin {
            color: rgba(245, 245, 245, 0.881);
            // transform: scale(1);
            transition: 0.3s all ease-in-out;
            &:before {
              content: "";
              height: 3px;
              background-color: #FAD204;
              position: absolute;
              bottom: -7px;
              left: 0;
              width: 0;
            }
            //&:hover {
            //  // color: $primary;
            //  // transform: scale(1.02);
            //  transition: 0.3s all ease-in-out;
            //  &:before {
            //    width: 100%;
            //  }
            //}
            &:after {
              content: "";
              background: white;
              height: 3px;
              display: block;
              left: 100%;
              top: 50%;
              transform: translate(0, -50%);
              width: 0;
              position: absolute;
              bottom: -29px;
              font-family: $font;
              transition: 0.3s width ease-in-out;
            }
          }
        }

      }
      .button-animation {
        overflow: hidden;
        a {
          transform: translate(0,50px);
          opacity:0;
          transition: 0.6s all ease-in-out;
        }
      }
      .animation {
        transform: translate(0) !important;
        opacity:1 !important;
        transition: 0.6s all ease-in-out;
        &.no-margin {
          color: rgba(245, 245, 245, 0.881);
          // transform: scale(1);
          transition: 0.6s all ease-in-out;
          &:before {
            content: "";
            height: 3px;
            background-color: #FAD204;
            position: absolute;
            bottom: -7px;
            left: 0;
            width: 100% !important;
            transition: all 1.5s ease-in-out;
          }
        }
      }

      .act-like-h1, h1 {
        //animation: 1s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1 slideInFromLeft;
        //animation-fill-mode: both;
        //transform: translate(0 , 300px);
        //opacity: 1;
        //transition: 0.6s all ease-in-out;
      }
      .subtitle {
        //animation-fill-mode: both;
        //animation: 1s cubic-bezier(0.22, 0.61, 0.36, 1) 0.5s 1 slideInFromLeft;
        //transform: translate3d(0, 0, 0);
      }
    }
  }

} // .banner

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translate3d(0, 60%, 0);
  }
  100% {
    opacity: 1;
  }
}
