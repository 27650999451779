.sidebar {

  .tile-cta {
    padding: 0;

    h2 {
      font-size: 1.25em;
    }

    .blurb {
      @extend .inner;
    }
  }
}

.tile-sidebar {
  margin: 0 0 1.5em;

  h2,h3 {
    margin-top: 0;
  }

  .career-info {

    li {
      &:hover {
        a {
          color: rgba(white,0.7);
        }
      }

      a {
        color: rgba(white,0.7);

        &:hover { color: white; }

        &:after {
          content: ', ';
        }

      }
    } // li


  } // .career-info

} // .tile-sidebar

.sidebar-siblings {
  h3 {
    text-transform: uppercase;
    border: 1px solid rgba(black,0.1);
    border-width: 0 0 1px;
    margin: 0 0 0.5em;
    padding: 0 0 0.5em;
  }

  &.knockout {
  } // &.knockout

  ul {
    li {
      a {
        display: block;
        padding: 0.25em 0;
      }
    }
  }

  @media screen {
    &.knockout {

      h3 { border-color: rgba(white,0.1); }

      ul {
        li {
          a {
            color: rgba(white,0.7);
          }

          &:hover, &.active {
            a {
              color: white;
            }
          }
        }
      } // ul

    } // &.knockout
  } // screen

} // .sidebar-siblings



.sidebar-archives {

  h3 { margin: 0; }

  .archive {
    @extend .nolist;
    // border-top: 1px solid rgba(white,0.1);

    .archive-months {
      margin: 0;
      padding: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 300ms ease;
      // border: 1px solid $gray-lightest;
      // background: $gray-lightest;

      li {
        padding: 0.25em 1.5em;
        transition: all 300ms ease;
      }
    }
  } // ul.archive

  h4 {
    font-size: 1em;
    margin: 0;
    padding: 1em 1.5em;
    color: rgba(white,0.7);
    position: relative;
    transition: all 300ms ease;
    cursor: pointer;
    border-top: 1px solid rgba(white,0.1);

    &:hover {
      color: $secondary;
    }

    &:after {
      content: '\f105';
      font-family: 'FontAwesome';
      font-weight: 300;
      font-size: 1em;
      position: absolute;
      right: 1.5em;
      top: 1em;
      transition: all 300ms ease;
    }
  } // h4

  .button {
    margin: 0.5em 0 0;
  }

  .expanded {

    .archive {

    }

    h4 {
      background: rgba(white,0.05);
      color: $secondary;
    }

    h4:after {
      transform: rotate(-270deg);
      color: $secondary;
    }

    .archive-months {
      padding: 0.5em 0;
      max-height: 100em;
    }
  }
} // .sidebar-archives
