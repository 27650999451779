.home {
  .banner {

    .banner-image {
      height: $slideAspectRatio;
      @media(max-width: $screen-sm) {
        height: 45vw;
        img {
          height:100%;
        }
      }
    }

    @media(max-width: $screen-sm) {
      .section-home {
        padding: 1em 0;
      }
    }


    .slick-dots {
      text-align: center;
      padding: 0.5em 0;

      li {
        padding: 0.25em;
        color: rgba(white,0.5);

        &.slick-active {
          color: white;
        }
      }
    }

  } // .banner

  @media (min-width: $screen-sm) {

    .banner {
      .banner-image {
        img {
          opacity: 0.5;
        }
      }

      &:not(.button) {
        text-transform: 0 2px 0.25em rgba(black,0.3);
      }

      .overlay {
        justify-content: center;
      }
    }

  } // min-sm

  @media (min-width: $screen-md) {

    .banner {

      .slick-dots {
        @include absolute(auto,0,0.5em);
      }
    }
  }

} // .home
