* {
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
}

::selection {
  color: white;
  background: $primary;
}

.bg-primary, .bg-primary-light, .bg-primary-dark {
  ::selection {
    color: $primary;
    background: rgba(white,0.5);
  }
}

.bg-secondary, .bg-secondary-light, .bg-secondary-dark {
  ::selection {
    color: $secondary;
    background: rgba(white,0.5);
  }
}

.bg-tertiary, .bg-tertiary-light, .bg-tertiary-dark {
  ::selection {
    color: $tertiary;
    background: rgba(white,0.5);
  }
}

body,html {
  margin: 0;
  padding: 0;
  background: white;
}

html {
  font-size: 16px;
}

.black {                color: black;            }
.gray-darkest {         color: $gray-darkest;    }
.gray-darker {          color: $gray-darker;     }
.gray-dark {            color: $gray-dark;       }
.gray {                 color: $gray;            }
.gray-light {           color: $gray-light;      }
.gray-lighter {         color: $gray-lighter;    }
.gray-lightest {         color: $gray-lightest;  }
.white {                color: white;            }

.primary {              color: $primary;         }
.primary-light {        color: $primary-light;   }
.primary-dark {         color: $primary-dark;    }
.secondary {            color: $secondary;        }
.secondary-light {      color: $secondary-light; }
.secondary-dark {       color: $secondary-dark;  }
.tertiary-light {       color: $tertiary-light;  }
.tertiary-dark {        color: $tertiary-dark;   }

.fill-black {            fill: black;            }
.fill-gray-darkest {     fill: $gray-darkest;    }
.fill-gray-darker {      fill: $gray-darker;     }
.fill-gray-dark {        fill: $gray-dark;       }
.fill-gray {             fill: $gray;            }
.fill-gray-light {       fill: $gray-light;      }
.fill-gray-lighter {     fill: $gray-lighter;    }
.fill-gray-lightest {     fill: $gray-lightest;  }
.fill-white {            fill: white;            }

.fill-primary {          fill: $primary;         }
.fill-primary-light {    fill: $primary-light;   }
.fill-primary-dark {     fill: $primary-dark;    }
.fill-secondary {        fill: $secondary;       }
.fill-secondary-light {  fill: $secondary-light; }
.fill-secondary-dark {   fill: $secondary-dark;  }
.fill-tertiary-light {   fill: $tertiary-light;  }
.fill-tertiary-dark {    fill: $tertiary-dark;   }

@media screen {
  .bg-black {             background-color: black;            }
  .bg-gray-darkest {      background-color: $gray-darkest;    }
  .bg-gray-darker {       background-color: $gray-darker;     }
  .bg-gray-dark {         background-color: $gray-dark;       }
  .bg-gray {              background-color: $gray;            }
  .bg-gray-light {        background-color: $gray-light;      }
  .bg-gray-lighter {      background-color: $gray-lighter;    }
  .bg-gray-lightest {      background-color: $gray-lightest;  }
  .bg-white {             background-color: white;            }

  .bg-primary {           background-color: $primary;         }
  .bg-primary-light {     background-color: $primary-light;   }
  .bg-primary-dark {      background-color: $primary-dark;    }
  .bg-secondary {         background-color: $secondary;       }
  .bg-secondary-light {   background-color: $secondary-light; }
  .bg-secondary-dark {    background-color: $secondary-dark;  }
  .bg-tertiary {          background-color: $tertiary;        }
  .bg-tertiary-light {    background-color: $tertiary-light;  }
  .bg-tertiary-dark {     background-color: $tertiary-dark;   }
}

.clearfix:after,
.clearfix:before {
  content:"";
  display:table;
}
.clearfix:after{
  clear:both;
}

.hidden {
  display: none;
}

.mfp-container {
  .mfp-close,
  .mfp-arrow {
    background: transparent;
  }
}

.mfp-bg {
  background: black;
  opacity: 0;
  transition: all 300ms ease;
}

.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-content {
  transition: all 300ms ease;
  transform: scale(0.5);
  opacity: 0;
}

.mfp-ready {
  .mfp-content {
    transform: scale(1);
    opacity: 1;
  }
}

.mfp-removing {
  .mfp-content {
    transform: scale(1.5);
    opacity: 0;
  }
}

#swipebox-bottom-bar,
#swipebox-bottom-bar.visible-bars {
  bottom: 0;
  transform: translate3d(0,0,0);
}

.section {
  padding: 3em 0;

  .titlebar.section {
    padding-top: 0;
  }

  .has-buttons.section {
    padding-bottom: 0;
  }

}

.container {
  margin: auto;
  width: 90%;
  max-width: $maxContainerWidth;
}

.container-max {
  max-width: $maxContainerWidth;
  margin: auto;
}

.sub-main {
  max-width: $noSidebarWidth;
  margin: auto;
}

.inner {
  padding: 1.5em;
}

.blurb {
  text-align: center;
  width: 90%;
  max-width: $maxBlurbWidth;
  margin: auto;
} // .blurb

.brand {
  display: block;
  margin: auto;
  max-width: 100%;
  width: $brandWidth;  // equal to svg width

  svg,
  img {
    display: block;
    width: $brandWidth;
    height: $brandHeight;
    overflow: hidden;
  }

} // .brand

.jac-tech {

  a {
    display: inline-block;
    width: 13.3571429em;
    height: 1.78571429em;
    max-width: 100%;

    svg {
      margin: 0;
      padding: 0;
      display: block;
      width: 13.3571429em;
      height: 1.78571429em;
      max-width: 100%;
    }
  }
} // .jac-tech

.knockout .jac-tech a {
  color: white;
  &:hover, &:focus { color: white; }
}

.spotlight-search {
  text-align: center;
  border: 1px solid rgba(black,0.1);
  background: white;

  .search-form {
    padding: 0 0.5em 0 1em;
    position: relative;
  }

  .input-wrap,
  input,
  button,
  .button {
    transition: all 300ms ease;
    box-shadow: none;
  }

  .cancel {
    color: white;
    margin-top: 1em;
    display: inline-block;
    cursor: pointer;

    &:hover {
      color: $secondary-light;
    }
  }

  .input-wrap {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    border-radius: 2em;
    padding: 0 1em;
  }

  input {
    border: none;
    outline: none;
    margin: 0;
    flex: 1 1 1em;
    box-shadow: none;
    background: transparent;
    padding-left: 0;
    padding-right: 2em;

    &:focus {
      box-shadow: none;
    }
  }

  .search-submit,
  button {
    width: 2em;
    padding: 0;
    text-align: center;
    border: none;
    color: $gray;
    margin: 0;
    background: transparent;
    @include absolute(calc(50% - 0.5em),0.5em,auto,auto);
    text-align: center;
  }

  &:hover,&:focus {
    .input-wrap {
      background: white;
    }
    button,
    .button {
      color: $gray-dark;

      &:hover {
        color: $gray-darker;
        background: transparent;
      }
    }
  }
} // .spotlight-search

.yoast-breadcrumb {
  padding: 1em 0;
  border-bottom: 1px solid $gray-lighter;

  .breadcrumbs {
    margin: 0;
    font-size: 0.75em;
  }

  a, span.breadcrumb_last {
    display: inline-block;
  }

  .fa-angle-right {
    margin: 0 0.25em;
    color: $gray-light;
  }

  a {
    color: $gray;

    &:hover {
      color: $secondary;
    }
  }
}

@media screen {
  .yoast-breadcrumb.knockout {

    .fa-angle-right {
      color: rgba(white,0.3);
    }

    a {
      color: rgba(white,0.7);

      &:hover, &:focus {
        color: white;
      }
    }
  }

} // media screen

.disabled { cursor: not-allowed; }

.pagination {
  margin: 0 auto;
  padding: 1em 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .subtitle {
    margin: 0;
  }

  .cats {
    flex: 1 1 100%;
    padding: 1em 0;

    li {
      display: inline-block;
      margin: 0 1em 0 0;

      a {
        color: rgba(white,0.5);
      }

      &:hover, &.active {
        a {
          color: white;
        }
      }
    }
  }

  .filter-options {
    font-size: 0.7em;
    text-transform: uppercase;
    flex: 1 1 5em;

    a {
      display: inline-block;
      margin: 0 1em 0 0;
      padding: 0 1em 0 0;
      border-right: 1px solid rgba(white,0.3);
      cursor: pointer;

      i {
        margin-right: 0.25em;
      }
    }
  }

  .filter-expand {
    flex: 1 1 100%;
    order: 5;
    overflow: hidden;
    max-height: 0;
    transition: all 300ms ease;
    margin: 0 -0.25em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    >div {
      margin: 0.5em 0.25em 0;
      flex: 1 1 10em;
    }
  }

  .filter-expand.expanded {
    margin: 0.5em -0.25em 0;
    max-height: 20em;
  }

  .title {
    flex: 1 1 5em;
  }

  .pagination-filter {

    li {
      display: inline-block;
      margin-left: 0.5em;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-size: 0.8em;
      margin: 0 1em 0 0;

      a {
        cursor: pointer;
        color: $gray-lighter;

        &:hover, &.selected {
          color: $secondary-light;
        }
      }

      &:first-of-type {
        border-right: 1px solid rgba(white,0.3);
        padding: 0 1em 0 0;
      }
    }
  }

  p {
    color: $gray-light;
  }

  .arrows {
    flex: 0 0 4em;
    text-align: right;
    font-size: 1em;

    a {
      display: inline-block;
      font-size: 1.5em;
      margin-left: 0.5em;
      cursor: pointer;

      &:hover {
        transform: scale(1.2);
      }

      &.disabled {
        cursor: not-allowed;
        transform: none;
        opacity: 0.1;
      }
    }
  }

  p { margin: 0; }

  .spotlight-search {
    margin: 0;
  }

  .service-index {
    flex: 1 1 100%;
    text-align: center;
    margin: auto;
    padding: 1em 0;

    span {
      color: $gray;

      a {
        color: white;

        &:hover {
          color: $secondary;
        }
      }

      &:before {
        content: '- ';
      }

      &:last-of-type {
        &:before {
          content: ' -';
        }
      }
    }
  } // .service-index

  &.service-index-wrap {
    .filter-options p {
      text-align: center;
    }
  }

  @media (min-width: $screen-md) {
    padding: 0.5em 0;

    .cats {
      flex: 1 1 10em;
    }

    .filter-options {
      @include flex-width(7em);
      font-size: 0.8em;

      a {
        display: none;
      }
    }

    .filter-expand,
    .filter-expand.expanded {
      max-height: none;
      flex: 1 1 10em;
      order: 0;
      justify-content: flex-end;
      margin: 0;

      >div {
        margin: 0 0.5em;
        flex: 0 1 12em;
      }

      div.filter-alpha {
        flex: 1 1 10em;
      }

    } // .filter-expand

    &.service-index-wrap {
      .filter-options {
        display: none;
      }
    }

    .service-index {
      flex: 1 1 10em;
      text-align: left;
    }

  } // min-md

  @media (min-width: $screen-lg) {

    .filter-expand,
    .filter-expand.expanded {


    } // .filter-expand

  } // min-lg

} // .pagination

@media screen {

  .knockout .pagination {

    .spotlight-search {
      margin: 0;
      background: transparent;
      border-color: rgba(white,0.5);

      input {
        color: white;
        @include field-placeholder-color(white);
      }
      .search-submit,
      button {
        background: transparent;
        i { color: white; }
      }
    } // .spotlight-search

    .select2-container--default {

      .select2-selection--single {
        background: transparent;
        border-color: rgba(white,0.5);

        .select2-selection__arrow b {
          border-color: white transparent transparent;
        }

        .select2-selection__rendered {
          color: white;
        }
      }

      &.select2-container--open {
        .select2-selection--single {
          .select2-selection__arrow b {
            border-color: transparent transparent white;
          }
        }
      }

    } // .select2-container--default

  } // .knockout .pagination
} // screen

.slick-dots {
  @extend .nolist;
  text-align: center;

  li {
    display: inline-block;
    padding: 0.5em;
    cursor: pointer;
    transition: all 300ms ease;
    cursor: pointer;
  }
}

.notification-badge {
  position: relative;
  font-size: 1.75em;
  width: 1em;
  height: 1em;
  color: $secondary;
  text-align: center;
  line-height: 0.7em;
  letter-spacing: 0;

  i {
    display: block;
    @include absolute(0);
    z-index: 1;
    width: 1em;
    height: 1em;
    overflow: hidden;
  }

  span {
    position: relative;
    z-index: 2;
    color: white;
    font-size: 0.42857143em;
  }
}

.hr {

  hr {
    display: none;
  }

  svg {
    width: 9.3125em;
    height: 0.125em;
  }

}

.hr.half {
  svg {
    width: 6.09375em;
  }
}

.feat-placeholder {
  padding-top: ($tileAspectRatio);
}

.playVid {
  @include absolute(0);
  display: block;

  i {
    position: absolute;
    top: 50%;
    right: 50%;
    margin: -0.5em -0.5em 0 0;
    color: white;
    font-size: 3em;
    text-shadow: 0 2px 2px rgba(black,0.3);
    transition: all 300ms ease;
    transform: scale(1);

    &:before {
      transition: all 300ms ease;
    }

  }

  &:hover {
    background: rgba(black,0.3);

    i {
      transform: scale(1.2);
    }
  }
}

.alert-warning {
  font-family: $font;
}
