//
// Variables
// --------------------------------------------------
$offCanvasWidth: 17.5em;
$font: Helvetica, Arial, sans-serif;

$brandWidth: auto;
$brandHeight:auto;

$tileAspectRatio: 400/640 * 100%;
$heroAspectRatio: 593/1920 * 100vw;
$slideAspectRatio: 733/1920 * 100vw;

$maxContainerWidth: 73.125em;
$maxBlurbWidth: 34em;
$noSidebarWidth: 60em;
//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-lightest:      #F9F9F9;
$gray-lighter:       #F0F0F0;
$gray-light:         #BBBBBB;
$gray:               #606060;
$gray-dark:          #313131;
$gray-darker:        #212121;
$gray-darkest:       #0F0F0F;

$success:            #39B54A;
$error:              #BE1E2D;

//## Social Media Colors.

$facebook:           #3b5998;
$messenger:          #4080ff;
$twitter:            #00aced;
$linkedin:           #007bb6;
$googleplus:         #dd4b39;
$youtube:            #bb0000;
$instagram:          #517fa4;
$pinterest:          #cb2027;
$behance:            #1769ff;
$tripadvisor:        #589442;
//## Site-Specific Colors.

$primary:            #20a2de !default;
$secondary:          #5bc0de !default;
$tertiary:           invert($primary);

$primary-light:      lighten( $primary,   10% );
$primary-dark:       darken(  $primary,   10% );

$secondary-light:    lighten( $secondary, 10% );
$secondary-dark:     darken(  $secondary, 10% );

$tertiary-light:     lighten( $tertiary,  10% );
$tertiary-dark:      darken(  $tertiary,  10% );

$red: $primary;
$red-light: $primary-light;
$red-dark: $primary-dark;

$yellow: $secondary;
$yellow-light: $secondary-light;
$yellow-dark: $secondary-dark;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change,
//## adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  30em !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  48em !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  62em !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  75em !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// Extra Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-xl:                  87.5em !default;
$screen-xl-min:              $screen-xl !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-xl-desktop:          $screen-xl-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 0.0625) !default;
$screen-sm-max:              ($screen-md-min - 0.0625) !default;
$screen-md-max:              ($screen-lg-min - 0.0625) !default;
