.footer-credits {
  text-align: center;
  padding: 1em 0;

  .copyright {
    margin: 0 0 0.5em;

    p, ul, li {
      display: inline-block;
      margin: 0;
      font-size:13px !important;
    }

    ul {
      margin: 0 0.5em;
    }

    li {
      margin: 0 0 0 0.5em;
      padding: 0 0 0 0.675em;
      border: 1px solid white;
      border-width: 0 0 0 1px;
      line-height: 1;

      a { cursor: pointer; }

      &:first-of-type {
        margin: 0;
        padding: 0;
        border-width: 0;
      }
    }

  }

  @media (min-width: $screen-sm) {
    text-align: left;

    p {
      margin: 0;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
    }

    .copyright {
      flex: 1 1 10em;
      margin: auto;
    }

    .jac-tech {
      padding: 0.25em 0 0;
    }

  } // min sm

} // .footer-credits

.menu-container{
  @media(min-width:$screen-sm){
    display:flex;
  }
  h3{
    margin-top:0;
    font-size:16px;
    font-weight:300;
  }
  p{
    font-size:12px;
    font-weight:300;
    letter-spacing: 1px;
  }
  .menu-primary{
    text-align:center;
    .menu-primary,.menu-secondary,a{
      text-align:center;
      font-size:16px;
      text-transform: uppercase;
      letter-spacing:1px;
      font-weight:300;
      padding:0;
      margin:0;
    }//menu-primary, menu-secondary, a
    .sub-menu{
      padding:0; margin-top:0.5em;
      text-align:center;
      li{ padding:0; }
      a{
        font-size:12px !important;
        text-transform:capitalize !important;
      }
    }//submenu
    @media(max-width:$screen-sm){
      .menu-item-has-children{ padding-top:1em;}
    }
    @media(min-width:$screen-sm){
      display:flex;
      text-align:left;
      @include flex-width(calc(100% - 16.666666667%));
      .menu-item-has-children{
        @include flex-width(25%);
        padding-right:1em;
      }//menu-item-has-children
      .sub-menu,.menu-item{ text-align:left; }

      .menu-admission,.menu-student-service, .menu-about-us{
        @include flex-width(25%);
      }
      .menu-contact {
        display: none;
      }
    }//mq sm
  }//menu-primary

  .contact-block{
    text-align:center;
    .location{
      display:flex;
    }
    @media(max-width:$screen-sm){
      .location{
        justify-content: center;
        align-items: center;
        i{ display:none;}
      }
    }
    @media(min-width:$screen-sm){
      @include flex-width(16.66666666667%);
      text-align:left;
      .location{ text-align:left; }
    }
  }
}//footer
