// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

/*
define as many $color-stops as needed
*/
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

/*
Applies to both absolute and fixed mixins
sides are listed clockwise, like padding and margin shorthand
leaving values empty will follow the same shorthand rules as padding and margin
"auto" is still a value and must be set if you want an edge to be auto position.
z-index is not part of this mixin because it should always be defined case-by-case
*/
@mixin absolute($top, $right: $top, $bottom: $top, $left: $right ) {
  position: absolute; top: $top; right: $right; bottom: $bottom; left: $left;
}

@mixin fixed($top, $right: $top, $bottom: $top, $left: $right ) {
  position: fixed; top: $top; right: $right; bottom: $bottom; left: $left;
}

/*
Use this when you need a flex child to keep a specific
width while other child items are flexible
works well with percentages and specific em values
*/
@mixin flex-width($value) {
  flex: 0 0 $value;
  max-width: $value;
  width: $value;
}

/*
This should be applied directly to an input element.
It can be nested within other classes.
*/
@mixin field-placeholder-color($field-placeholder-color, $weight: 400) {

  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    $field-placeholder-color;
    opacity:  1;
    font-weight: $weight;
    -webkit-font-smoothing: antialiased;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
     color:    $field-placeholder-color;
     opacity:  1;
     font-weight: $weight;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
     color:    $field-placeholder-color;
     opacity:  1;
     font-weight: $weight;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
     color:    $field-placeholder-color;
     opacity:  1;
     font-weight: $weight;
  }

}
