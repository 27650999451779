.tile-cta {
  position: relative;

  h2,h3 {
    margin: 0 0 0.25em;
  }

  .button {
    margin-top: 1em;

    &.inverted {
      border-width: 1px;
    }
  }

  .blurb {
    position: relative;
    z-index: 2;
  }

  .tile-bg {
    opacity: 0.1;
    @include absolute(0);
    background-size: cover;
    background-position: center center;
    filter: grayscale(1);
  }

} // .tile-cta

.tile-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;

  @media (min-width: $screen-sm) {
    margin: -1em;
  }

} // .tile-wrap

.ajax-button-container{
  @include flex-width(100%);
  text-align:center;
}//ajax-button-container

.tile {
  position: relative;
  @include flex-width(calc(100% - 1em));
  margin: 0.5em;
  padding: 0 0 4em;

  h2,h3 {
    margin: 0 0 0.25em;
  }

  .timestamp {
    @include absolute(0,auto,auto,0);
    z-index: 5;
    padding: 0.5em 1.5em;

    p {
      margin: 0;
      font-size: 0.8em;
      font-weight: bold;
    }
  }

  .tile-buttons {
    @include absolute(auto,0,0);
    z-index: 3;
  }

  .button {
    margin: 0.5em 0.25em 0;
    font-size: 0.75em;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .feat-placeholder {
    padding-top: $tileAspectRatio;
    position: relative;
    overflow: hidden;
    text-align: center;
    background-color: rgba(black,0.9);

    &:before {
      content: '\f059';
      font-family: 'FontAwesome';
      font-size: 35vw;
      color: rgba(white,0.1);
      @include absolute(calc(50% - 0.5em), 0, auto );
    }

  } // .feat-placeholder

  &.has-terms {
    padding: 0 0 6.5em;

    .tile-buttons {
      @include absolute(auto,0,2.5em);
    }
  }

  @media (min-width: $screen-sm) {
    @include flex-width(calc(50% - 2em));
    margin: 1em;
    .feat-placeholder:before { font-size: 15vw; }
  } // min-sm

  @media (min-width: $screen-md) {
    @include flex-width(calc(33.33% - 2em));
    .feat-placeholder:before { font-size: 10vw; }
  } //min-md

} // .tile

.tile-career_program , .tile-language_program {
  padding: 0 0 0em;
}

.tile-leadin {
  padding: 0;
  display: grid;

  .tile-image {
    position: relative;
    margin: 0 0 1em;
  }

  .overlay {
    @include absolute(0);
    display: flex;
    flex-wrap: wrap;
    background: rgba(black,0.5);
  }

  h3 {
    margin: auto;
    padding: 1em;
    text-align: center;
    flex: 1 1 100%;
    font-weight: 700;
    text-shadow: 0 1px rgba(black,0.3);
  }

} // .tile-leadin

.tile-categories {
  @include absolute(calc(100% - 2.5em),0,0);
  z-index: 4;
  background: white;
  border-top: 1px solid $gray-lighter;
  overflow: hidden;
  text-align: left;
  padding: 0.5em 1.5em 0;
  transition: all 300ms ease;
  min-height: 2.5em;

  li {
    font-size: 0.625em;
  }

  a {
    color: $gray-light;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    display: block;
    padding: 0.5em;

    i {
      color: $secondary;
      margin-right: 0.25em;
    }

    &:hover {
      color: $primary;
    }
  } // a

  li:last-of-type a {
    padding: 0.5em 0.5em 1em;
  }

  &:hover {
    top: auto;
  }

} // .tile-categories

.course-sidebar {
  .button {
    margin:0em 0em 0.7em;
    display: block;
  }
  .single-course-section {
    padding: 1em;

    li {
      h4 {
        margin: 0em 0em 0.5em;
        text-transform: uppercase;
        font-size: 1em;
      }
    }

    .list-course-length, .list-course-credits {
      display: flex;
      justify-content: space-between;
    }

    .list-prerequisites {
      h4 {
        margin-top: 0.5em;
      }
    }
  }
}

.tile-post          .feat-placeholder:before {  content: '\f1ea'; }
.tile-page          .feat-placeholder:before {  content: '\f15b'; }
.tile-event         .feat-placeholder:before {  content: '\f073'; }
